<template>
  <div v-loading="loading">
    <!-- <div v-if="templateRules.length && !addOrEditingRules">
      <div class="mb-1" style="width: 100%">
        All rules
        <div class="action-links">
          <el-input v-model="searchQuery" placeholder="Search" clearable @clear="clearSearch" class="mb-1" style="width: 250px; margin-right: 10px;" />
          <el-button type="danger" plain icon="el-icon-plus" size="mini" @click="addNewRule(true)">Form Rules</el-button>
        </div>
      </div>
      <el-collapse accordion>
        
        <el-collapse-item  name="1" v-for="(rule, index) in templateRules" :key="index + '_collapse'"
          class="rule-collapse">
          <template slot="title" style="border: 1px solid #e4e7ed">
            <div style="width: 100%" class="ml-1">
              {{ rule.name }} 
              <el-popover
      v-model="rule.descriptionVisible"
      placement="top-start"
      trigger="hover"
    >
      <el-link :underline="false" type="info" class="mr-1" slot="reference"  @click.stop="showInfo(index)">
        <i class="el-icon-info"></i>
      </el-link>
      <p>{{ rule.description }}</p>
    </el-popover>
              <div class="action-links" >
                <el-switch
    v-model="rule.isRuleActive"
    class="ml-2"
    style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
  />
                <el-link :underline="false" type="primary" class="mr-1" @click.stop="editRule(index)"><i
                    class="el-icon-edit"></i>
                </el-link>
                <el-link :underline="false" type="primary" class="mr-1" @click.stop="CopyRule(index)"><i
                    class="el-icon-copy-document"></i>
                </el-link>
                <el-link type="danger" :underline="false" @click.stop="deleteRule(index)"><i class="el-icon-delete"></i>
                </el-link>
              </div>
            </div>
          </template>
          <div class="rule-preview">
            <div v-for="(r, i) in rule.conditions" :key="i + '_preview_rule'">
              <div class="d-flex" style="margin-top: 2px">
                <img src="@/assets/img/icons/formRules/if-form-rule.svg" class="mr-1" />
                <b class="mr-1"> IF </b>{{ getFieldLabel(r.selectField) }} is
                {{ getFieldOperator(r) }}
                {{ fetchFieldValue(r) }}
                <b class="ml-1" v-if="i !== rule.conditions.length - 1">{{
                  rule.query_type
                }}</b>
              </div>
            </div>
            <div v-for="(r, i) in rule.actions" :key="i + '_preview_action'">
              <div class="d-flex" style="margin-top: 5px">
                <img :src="require(`@/assets/img/icons/formRules/${r && r.operator_type ? r.operator_type : 'HIDE'
                  }.svg`)
                  " class="mr-1" height="20px" />
                <b class="mr-1"> {{ r.operator_type }} </b>{{ r.selectField }}
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div> -->
    <div v-if="templateRules.length && !addOrEditingRules">
    <div class="mb-1" style="width: 100%">
      All rules
      <div class="action-links">
        <el-input v-model="searchQuery" placeholder="Search" clearable @clear="clearSearch" class="mb-1" style="width: 250px; margin-right: 10px;" />
        <el-button type="danger" plain icon="el-icon-plus" size="mini" @click="addNewRule(true)">Form Rules</el-button>
      </div>
    </div>
    <div class="form-rules-wrapper">
    <el-collapse accordion>
      <el-collapse-item 
        v-for="(rule, index) in filteredRules" 
        :key="index + '_collapse'"
        :name="index + 1"
        class="rule-collapse"
      >
        <template slot="title" style="border: 1px solid #e4e7ed">
          <div style="width: 100%" class="ml-1">
            {{ rule.name }} 
            <el-popover
              v-model="rule.descriptionVisible"
              placement="top-start"
              trigger="hover"
            >
              <el-link :underline="false" type="info" class="mr-1" slot="reference"  @click.stop="showInfo(index)">
                <i class="el-icon-info"></i>
              </el-link>
              <p>{{ rule.description }}</p>
            </el-popover>
            <div class="action-links" >
              <el-switch
                v-model="rule.isRuleActive"
                class="ml-2"
                style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
              />
              <el-link :underline="false" type="primary" class="mr-1" @click.stop="editRule(index)"><i
                class="el-icon-edit"></i>
              </el-link>
              <el-link :underline="false" type="primary" class="mr-1" @click.stop="CopyRule(index)"><i
                class="el-icon-copy-document"></i>
              </el-link>
              <el-link type="danger" :underline="false" @click.stop="deleteRule(index)"><i class="el-icon-delete"></i>
              </el-link>
            </div>
          </div>
        </template>
        <div class="rule-preview">
          <div v-for="(r, i) in rule.conditions" :key="i + '_preview_rule'">
            <div class="d-flex" style="margin-top: 2px">
              <img src="@/assets/img/icons/formRules/if-form-rule.svg" class="mr-1" />
              <b class="mr-1"> IF </b>{{ getFieldLabel(r.selectField) }} is
              {{ getFieldOperator(r) }}
              {{ fetchFieldValue(r) }}
              <b class="ml-1" v-if="i !== rule.conditions.length - 1">{{
                rule.query_type
              }}</b>
            </div>
          </div>
          <div v-for="(r, i) in rule.actions" :key="i + '_preview_action'">
            <div class="d-flex" style="margin-top: 5px">
              <img :src="require(`@/assets/img/icons/formRules/${r && r.operator_type ? r.operator_type : 'HIDE'
                }.svg`)
                " class="mr-1" height="20px" />
              <b class="mr-1"> {{ r.operator_type }} </b>{{ r.selectField }}
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
  </div>
    <div class="rule-container" v-else-if="addOrEditingRules">
      <el-row>
        <el-col :span="24">
          Rule name
          <el-input v-model="currentRule.name" placeholder="Rule name" class="mb-1" size="mini"
            @change="checkRuleName(currentRule.name)">
          </el-input>
        </el-col>
      </el-row>
      <el-row>
  <el-col :span="24">
    Description
    <el-input v-model="currentRule.description" placeholder="Description" class="mb-1" size="mini">
    </el-input>
  </el-col>
</el-row>
      <div v-if="!currentRule.conditions.length">
        <el-button type="danger" icon="el-icon-plus" plain @click="addNewCondition(false)" class="add-rule"
          size="mini">Add condition</el-button>
      </div>
      <div v-else>
        <div>
          <b>If </b>
          <el-select v-model="currentRule.query_type" placeholder="Query type" size="mini">
            <el-option value="AND" label="all"> all </el-option>
            <el-option value="OR" label="any one"> any one </el-option>
          </el-select>
          of the following is true
        </div>
        <el-scrollbar wrap-style="max-height: 150px;" ref="configureScroll">
          <div class="rule-row mt-1" v-for="(rule, index) in currentRule.conditions" :key="index + '_conditions'">
            <div class="rule-inner-row">
              <el-select v-model="rule.selectField" size="mini" placeholder="Select field" @change="(changedValue) => checkCurrentFieldData(changedValue, rule)" filterable>
  <el-option
    v-for="(op, i) in [...includeDataTableFields]
      .filter(e => e.inputType !== 'DATA_TABLE' && e.inputType !== 'ENTITY_TABLE')
      .sort((a, b) => a.label.localeCompare(b.label))"
    :key="i + op.key"
    :value="op.key"
    :label="op.label">
    {{ op.label }}
  </el-option>
  <el-option value="userType" label="User Type"></el-option>
  <el-option value="IsUserType" label="Is User Type"></el-option>
  <el-option value="apply_rules" label="Apply Rules At"></el-option>
  <el-option value="created_by" label="Created By"></el-option>
  <el-option value="updated_by" label="Updated By"></el-option>
  <el-option value="created_at" label="Created Date"></el-option>
  <el-option value="updated_at" label="Updated Date"></el-option>
</el-select>

              <el-select v-model="rule.operator" v-if="rule.data_type !== 'BOOLEAN' &&
                rule.selectField != 'apply_rules'
                " size="mini" :disabled="!rule.selectField" placeholder="Operator" class="ml-1">
                <el-option v-for="(operator, index) of getCurrentFieldOperators(rule)" :key="index + '_operator'"
                  :label="operator.title" :value="operator.value"></el-option>
              </el-select>
              <div v-if="['exists', 'notexists', 'click'].indexOf(rule.operator) == -1
                " class="d-flex">
                <el-select v-model="rule.data_source" size="mini" placeholder="Value type" :disabled="!rule.operator"
                  class="ml-1 mr-1" v-if="rule.data_type != 'BOOLEAN' &&
                    rule.data_type != 'userType' &&
                    rule.data_type != 'IsUserType' &&
                    rule.selectField != 'apply_rules'&& rule.input_type!='QUESTION'
                    ">
                  <template v-if="checkIsSelect(rule)">
                    <el-option value="field_option" label="From Field"></el-option>
                  </template>
                  <template v-else>
                    <el-option v-for="(source, index) of allowedDataSources(rule)" :key="index + '_data_source'"
                      :label="source" :value="source"></el-option>
                      
                    <el-option value="TEMPLATE" label="TEMPLATE" v-if="rule.operator != 'between' && rule.data_type!='created_by' && rule.data_type!='updated_by'&& rule.data_type!='created_at'&& rule.data_type!='updated_at'">
                      TEMPLATE
                    </el-option>
                    <el-option   value="login" label="Login User" v-if="rule.data_type == 'created_by' || rule.data_type == 'updated_by'"></el-option>
                    <el-option   value="value" label="VALUE" v-if="rule.data_type == 'created_by' || rule.data_type == 'updated_by'"></el-option>
                    <el-option v-if="rule.input_type == 'DATE'" value="TODAY" label="today">
                      Today
                    </el-option>
                    <el-option value="GLOBAL_VARIABLE" label="GLOBAL_VARIABLE" v-if="rule.input_type == 'SINGLE_LINE_TEXT' ||
                      rule.input_type == 'NUMBER' && rule.operator != 'between'
                      ">
                      GLOBAL_VARIABLE
                    </el-option>
                    <el-option v-if="rule.data_type == 'STRING' &&
                      allowedLoginRuleOp.indexOf(rule.operator) != -1
                      " value="login_user" label="FROM LOGIN USER"></el-option>
                  </template>
                </el-select>

                <el-select v-if="rule.input_type === 'YES_OR_NO'" v-model="rule.value" size="mini"
                  style="margin-left: 10px;">
                  <el-option value="YES" label="YES">Yes</el-option>
                  <el-option value="NO" label="NO">No</el-option>
                </el-select>
                <el-select v-if="rule.data_source == 'GLOBAL_VARIABLE'" v-model="rule.value" size="mini"
                  no-data-text="No Global Variables with Field" @change="(value) => addGlobalVaraibleData(value, rule)">
                  <el-option v-for="(gbVab, index) of selectedGlobalVariablesList(rule)" :key="index" :label="`${gbVab.label} -> ${gbVab.value ? gbVab.value : ''
                    }`" :value="gbVab._id"></el-option>
                </el-select>
                <el-select v-model="rule.value" size="mini" v-if="rule.data_source == 'login_user'" @change="(changedValue) => checkCurrentFieldValue(changedValue, rule)
                  ">
                  <el-option v-for="(op, index) in loginUserFields" :key="index + op.value" :label="op.label"
                    :value="op.value">{{ op.label }}</el-option>
                </el-select>
                <el-select v-if="rule.data_type === 'userType'" v-model="getUserTypeModel"  size="mini" multiple collapse-tags
                  style="margin-left: 10px;">
                  <el-option v-for="(user, index) in getUserTypeList" :key="index"
                    :label="user.role.title + '->' + user.name" :value="user._id">
                  </el-option>
                </el-select>
               <div style="display: flex;" v-if="(rule.input_type == 'NUMBER'|| rule.input_type=='CURRENCY'|| rule.input_type=='FORMULA'||rule.input_type=='AGGREGATE_FUNCTION'||rule.input_type=='STAR_RATING') && rule.operator=='between' && rule.data_source!='TEMPLATE' && rule.data_source=='VALUE'
 ">
                 <el-input-number 
                  v-model="rule.startValue" 
                  :controls="false" 
                  size="mini" 
                  placeholder="starting value"
                  style="margin-right: 15px;">
                </el-input-number>
                  <span>To&nbsp;</span>
                  <el-input-number v-model="rule.endValue" :controls="false" size="mini" placeholder="ending value"
                    style="margin-left: 15px;">
                  </el-input-number>
                </div>
                <div style="display: flex;"
                  v-if="rule.input_type === 'DATE' && rule.operator == 'ranges' && rule.data_source === 'VALUE'">
                  <el-date-picker v-model="rule.startValue" type="date" size="mini" placeholder="Starting Date"
                    style="margin-right: 15px;"></el-date-picker>
                  <span>To&nbsp;</span>
                  <el-date-picker v-model="rule.endValue" type="date" placeholder="Ending Date" size="mini"
                    style="margin-left: 15px;"></el-date-picker>
                </div>

                <el-select v-if="rule.data_type === 'IsUserType'" v-model="rule.value" size="mini"
                  style="margin-left: 10px;">
                  <el-option value="ApplicationUser-Entities" label="ApplicationUser-Entities">
                    ApplicationUser-Entities
                  </el-option>
                  <el-option value="ApplicationUser" label="ApplicationUser">
                    ApplicationUser
                  </el-option>
                  <el-option value="CompanyUser" label="CompanyUser">
                    CompanyUser
                  </el-option>

                </el-select>
                <el-select v-if="rule.input_type === 'IsUserType' && rule.value === 'ApplicationUser-Entities'" v-model="rule.application_user_types" size="mini" multiple collapse-tags style="margin-left: 10px;">
   <el-option v-for="(user, index) in filteredEntities" :key="index" :label="user.name" :value="user._id">
   </el-option>
 </el-select>
 <el-select v-if="(rule.input_type === 'created_by'|| rule.input_type === 'updated_by') && rule.data_source=='value' && rule.data_source!='login'" v-model="rule.value" size="mini" multiple collapse-tags style="margin-left: 10px;">
  <el-option v-for="(user, index) in users.data" :key="index" :label="user.email" :value="user._id">
  </el-option> 
 </el-select>
                <el-select v-if="rule.selectField === 'apply_rules'" v-model="rule.value" size="mini"
                  style="margin-left:10px;">
                  <el-option value="FormBuilders" label="FormBuilders">
                    FormBuilders</el-option>
                  <el-option value="Entities" label="Entities">
                    Entities
                  </el-option>
                  <el-option value="Both" label="Both"> Both </el-option>
                </el-select>
                <el-select v-if="rule.selectField === 'apply_rules' && rule.value == 'FormBuilders'" v-model="rule.formbuilderId" size="mini"
                  style="margin-left:10px;">
                  <el-option v-for="form in formbuildersList" :key="form._id" :value="form._id" :label="form.name"></el-option>
                </el-select>
                <el-select v-if="rule.input_type === 'WEEKDAYS'" v-model="rule.value" size="mini" multiple collapse-tags>
                  <el-option v-for="(dt, index) of weekdayOptions" :key="index + '_field_option'" :label="dt.name"
                    :value="dt.value">{{ dt.name }}</el-option>
                </el-select>
                <el-select v-model="rule.value" size="mini" v-if="rule.data_source == 'TODAY' && rule.input_type == 'DATE'
                  ">
                  <el-option :label="todayDate" :value="todayDate"></el-option>

                </el-select>
              <el-select v-if="rule.input_type === 'STAR_RATING' &&
                  rule.data_source == 'VALUE'  && rule.operator!='between'
                  " v-model="rule.value" size="mini">
                  <el-option v-for="(dt, index) of starRating_options" :key="index + '_field_option'" :label="dt.name"
                    :value="dt.value">{{ dt.name }}</el-option>
                </el-select>
               <el-select v-else-if="rule.input_type === 'CHECKBOX'" v-model="rule.value" size="mini"
                  style="margin-left: 10px;">
                  <el-option :value="true" label="checked">checked</el-option>
                  <el-option :value="false" label="unchecked">unchecked</el-option>
                </el-select>
                <el-select v-else-if="rule.input_type === 'RADIO'" v-model="rule.value" size="mini">
                  <el-option :value="true" label="checked">checked</el-option>
                  <el-option :value="false" label="unchecked">unchecked</el-option>
                </el-select>

                <el-select v-model="rule.value" v-else-if="rule.data_source == 'ENTITY_DATA_LIST'" multiple collapse-tags
                  size="mini">
                  <el-option v-for="(temp, i) in entilistAsOptions(rule)" :key="temp._id + '_' + index + '_' + i"
                    :value="temp._id" :label="getLabel(temp, i)">{{ getLabel(temp, i) }}
                  </el-option>
                </el-select>

                <el-select v-model="rule.value" v-else-if="rule.input_type == 'LIST'" size="mini"
                  :multiple="checkIsMultiple(rule)">
                  <el-option v-for="gr in filteredFields(rule.selectField)" :key="gr.key + '_gr'" :value="gr.key"
                    :label="gr.label"></el-option>
                  <el-option v-for="(dt, index) of getCurrentFieldOptions(rule)" :key="index + '_field_option'"
                    :label="dt.name" :value="dt">{{ dt.name }}
                  </el-option>
                </el-select>
                <el-select v-model="rule.value" v-else-if="rule.data_source == 'field_option' &&
                  rule.input_type != 'WEEKDAYS' && rule.data_type != 'userType' && rule.data_type != 'IsUserType'
                  " collapse-tags size="mini" :multiple="checkIsMultiple(rule)" style="width: 180px;">
                  <!-- <el-option v-for="gr in filteredFields(rule.selectField)" :key="gr.key + '_gr'" :value="gr.key"
                    :label="gr.label"></el-option> -->
                  <el-option v-for="(dt, index) in getCurrentFieldOptions(rule)" :key="index + '_field_option'"
                    :label="dt" :value="dt">{{ dt }}
                  </el-option>
                </el-select>
                <el-select v-model="rule.value" v-else-if="rule.data_source == 'TEMPLATE' &&
                  rule.input_type !== 'CHECKBOX_GROUP'
                  " size="mini" placeholder="Select template field" multiple collapse-tags>
                  <el-option v-for="(temp, i) in geTemplateFieldsByType(rule)" :key="temp.key + '_' + index + '_' + i"
                    :value="temp.key" :label="temp.label">{{ temp.label }}
                  </el-option>
                </el-select>
                <el-input-number v-model="rule.value" v-else-if="rule.data_source == 'VALUE' && rule.data_type == 'NUMBER' && rule.operator != 'between'
                  " :controls="false" size="mini" ></el-input-number>
                <el-input v-model="rule.value" v-if="rule.input_type === 'SINGLE_LINE_TEXT' &&
                  rule.data_source != 'TEMPLATE' &&
                  rule.data_source != 'GLOBAL_VARIABLE' &&
                  rule.data_source != 'login_user'
                  " :controls="false" placeholder="Enter text" :disabled="!rule.operator" size="mini"></el-input>
                <el-input v-model="rule.value" v-if="rule.input_type === 'MULTI_LINE_TEXT' &&
                  rule.data_source != 'TEMPLATE' &&
                  rule.data_source != 'GLOBAL_VARIABLE' &&
                  rule.data_source != 'login_user'
                  " :controls="false" placeholder="Enter text" :disabled="!rule.operator" size="mini"></el-input>
                <el-date-picker v-model="rule.value" size="mini" v-else-if="rule.data_source == 'VALUE' && rule.data_type == 'DATE'&& rule.operator!='ranges'
                  " :format="getDefaultDateFormat &&
    getDefaultDateFormat.includes('Do') == false
    ? getDefaultDateFormat
      .replace('YYYY', 'yyyy')
      .replace('DD', 'dd')
    : 'MM-dd-yyyy'
    "></el-date-picker>
     <el-date-picker v-model="rule.value" size="mini" v-else-if="rule.data_source == 'VALUE' && rule.data_type == 'created_at'||rule.data_type == 'updated_at'
                  " :format="getDefaultDateFormat &&
    getDefaultDateFormat.includes('Do') == false
    ? getDefaultDateFormat
      .replace('YYYY', 'yyyy')
      .replace('DD', 'dd')
    : 'MM-dd-yyyy'
    "></el-date-picker>

                <!-- <el-date-picker
                v-else-if="
                  rule.data_source === 'VALUE' &&
                  rule.data_type === 'DATE_RANGE' 
                  
                "
                v-model="rule.value"
                type="daterange"
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
                size="mini"
              ></el-date-picker> -->
                <el-time-picker v-model="rule.value" size="mini" v-else-if="rule.data_source === 'VALUE' && rule.data_type === 'TIME' && rule.operator != 'ranges'
                  " :picker-options="{ format: 'HH:mm:ss' }"></el-time-picker>

                <el-date-picker v-else-if="rule.data_source === 'VALUE' &&
                  rule.data_type === 'DATE_TIME'
                  " v-model="rule.value" type="datetime" size="mini" placeholder="Select date and time">
                </el-date-picker>
                <el-date-picker v-else-if="rule.data_source === 'VALUE' &&
                  rule.data_type === 'DATE_TIME_RANGE'
                  " v-model="rule.value" type="datetimerange" range-separator="To" start-placeholder="Start date"
                  end-placeholder="End date" size="mini" >
                </el-date-picker>
                <P v-else-if="rule.data_type == 'userType'"> </P>
                <P v-else-if="rule.input_type == 'IsUserType'"> </P>
                <!-- <el-input
                  v-else
                  :disabled="!rule.data_source"
                  v-model="rule.value"
                  placeholder="Value"
                  size="mini"
                  class="ml-1"
                >
                </el-input> -->
              </div>
            </div>
            <el-link class="mr-1" type="primary" :underline="false" @click="addNewCondition(false)"><i
                class="el-icon-circle-plus"></i>
            </el-link>
            <el-link class="mr-1" type="danger" :underline="false" @click="deleteCondition(index)"
              :disabled="currentRule.conditions.length == 1"><i class="el-icon-remove"></i>
            </el-link>
          </div>
        </el-scrollbar>
        <div class="mt-1">
          <b>Then</b> Perform the following actions
          <el-select v-model="currentRule.when" placeholder="When" size="mini">
            <el-option value="IMMEDIATELY" label="Immediately">
              Immediately
            </el-option>
            <el-option value="AFTER_SAVE" label="After save">
              After save
            </el-option>
          </el-select>
          <el-tooltip class="item" effect="dark" content="Show alert before perform" placement="top-start"
            v-if="currentRule.when == 'AFTER_SAVE'">
            <el-checkbox v-model="currentRule.show_alert"> </el-checkbox>
          </el-tooltip>
          <el-input placeholder="Alert message" v-model="currentRule.alert_message"
            v-if="currentRule.when == 'AFTER_SAVE' && currentRule.show_alert" size="mini" class="mt-1">
          </el-input>
          <span v-if="currentRule.when == 'AFTER_SAVE' && currentRule.show_alert" class="warning">
            Note: Show in popup fields will not be shown in the template
          </span>
        </div>
        <el-scrollbar wrap-style="max-height: 150px;" ref="configureScroll">
          <div class="rule-row mt-1" v-for="(action, index) in currentRule.actions" :key="index + '_action'">
            <div class="rule-inner-row" style="display: flex">
              <!-- {{ action.input_type }} -->
              <el-select v-model="action.selectField" size="mini" placeholder="Select field" @change="(changedValue) => checkCurrentFieldData(changedValue, action)" filterable>
                <el-option v-for="(op, i) in allowedContentFields" :key="i + '_actions_field'" :value="op.key"
                  :label="op.label ? op.label : truncateContent(op.content, 10) ? truncateContent(op.content, 10) : op.input_type + ' ' + i">

                </el-option>
                <el-option v-for="(op, i) in includeDataTableFields" :key="i + '_action_field'" :value="op.key"
                  :label="op.label">
                  {{ op.label }}
                </el-option>
                <el-option label="Notification" value="NOTIFICATION">
                  Notification
                </el-option>
               
              </el-select>
              <template v-if="action.selectField == 'NOTIFICATION'">
                <el-select v-model="action.notify_type" placeholder="Type" size="mini" class="ml-1">
                  <el-option value="message" label="Message"></el-option>
                  <el-option value="success" label="Success"></el-option>
                  <el-option value="warning" label="Warning"></el-option>
                  <el-option value="error" label="Error"></el-option>
                </el-select>
                <el-input v-model="action.message" placeholder="Message"  size="mini" class="ml-1">
                </el-input>
                <el-select v-if="action.selectField == 'NOTIFICATION'" v-model="action.data_source" size="mini">
                  <el-option value="Value" label="value">Value</el-option>
                  <el-option value="Template" label=" From template">
                    From template
                  </el-option>
                 
                </el-select>
                <el-input v-if="action.data_source === 'Value' && action.selectField == 'NOTIFICATION'" v-model="action.value" size="mini" class="small-input" >
                </el-input>
                <el-select v-if="action.data_source === 'Template'" v-model="action.value" size="mini">
                    <el-option v-for="(temp, i) in NumberFields" :key="temp.key + '_' + index + '_' + i"
                    :value="temp.key" :label="temp.label">{{ temp.label }}
                  </el-option>
     
                </el-select>
                <el-tooltip class="item" effect="dark" content="Show alert once" placement="top-start"
                  >
                  <el-checkbox v-model="action.show_alert_once"></el-checkbox>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Restrict savig" placement="top-start"
                  v-if="fromDataTable">
                  <el-checkbox v-model="action.restrict_save"></el-checkbox>
                </el-tooltip>
              </template>
             
              <template v-else>
                <el-select v-model="action.operator_type" placeholder="Query type" size="mini" class="ml-1">
                  <template v-if="action.input_type == 'DATA_TABLE'">
                    <el-option value="SHOW" label="Show"> Show </el-option>
                    <el-option value="HIDE" label="Hide"> Hide </el-option>
                    <el-option value="DISABLE" label="Disable"> Disable </el-option>
                  </template>
                  <template v-else-if="action.selectField && action.selectField.includes('#')
                    ">
                    <el-option value="SHOW" label="Show"> Show </el-option>
                    <el-option value="HIDE" label="Hide"> Hide </el-option>
                    <el-option value="DISABLE" label="Disable"> Disable </el-option>
                    <el-option value="UPDATE" label="Update" :disabled="action.input_type == 'FORMULA' ||
                      action.input_type == 'ACTION_BUTTON' ||
                      action.input_type == 'AGGREGATE_FUNCTION' ||
                      action.input_type == 'AUTO_INCREMENT_NUMBER'|| action.input_type == 'VIDEO'
                      ">
                      Update
                    </el-option>
                  </template>
                  <template v-else>
                    <el-option v-if="currentRule.when == 'AFTER_SAVE' &&
                      currentRule.show_alert
                      " value="SHOW_IN_POPUP" label="Show in popup">
                      Show in popup
                    </el-option>
                    <el-option value="SHOW" label="Show"> Show </el-option>
                    <el-option value="HIDE" label="Hide"> Hide </el-option>
                    <el-option value="DISABLE" label="Disable" :disabled="
                      action.input_type == 'AUTO_INCREMENT_NUMBER' || action.input_type == 'HEADING' || action.input_type == 'IMAGE' ||  action.input_type == 'SINGLE_LINE_CONTENT' || action.input_type == 'SIGNATURE' || action.input_type == 'VIDEO'||action.input_type == 'CAPTCHA'
                      ">
                      Disable
                    </el-option>
                    <el-option value="UPDATE" label="Update" :disabled="action.input_type == 'FORMULA' ||
                      action.input_type == 'ACTION_BUTTON' ||
                      action.input_type == 'AGGREGATE_FUNCTION' ||
                      action.input_type == 'AUTO_INCREMENT_NUMBER' || action.input_type == 'IMAGE' || action.input_type == 'HEADING' || action.input_type == 'FILE' || action.input_type == 'SINGLE_LINE_CONTENT' || action.input_type == 'SIGNATURE' || action.input_type == 'CONCATENATE' || action.input_type == 'PHONE_COUNTRY_CODE'|| action.input_type == 'VIDEO'||action.input_type == 'CAPTCHA'
                      ">
                      Update
                    </el-option>
                    <el-option value="changelabel" label="Change Label">Change Label</el-option>
                    <el-option label="Add Options" value="PUSH" v-if="(action.input_type == 'WEEKDAYS' || action.input_type == 'MULTI_SELECT') && checkIsMultiple(action)"></el-option>
                    <el-option label="Remove Options" value="PULL" v-if="(action.input_type == 'WEEKDAYS' || action.input_type == 'MULTI_SELECT') && checkIsMultiple(action)"></el-option>
                    <el-option value="Make_this_field_required" label="Make this field required">
                      Make this field required</el-option>
                    <el-option value="Make_this_field_unrequired" label="Make this field unrequired">
                      Make this field unrequired</el-option>
                    <el-option value="INCREMENT" label="increment" v-if="action.input_type === 'NUMBER' ||
                      action.input_type === 'CURRENCY'
                      ">
                      Increment
                    </el-option>
                    <el-option value="DECREMENT" label="Decrement" v-if="action.input_type === 'NUMBER' ||
                      action.input_type === 'CURRENCY'
                      ">
                      Decrement
                    </el-option>
                    <el-option value="MULTIPLY" label="Multiply" v-if="action.input_type === 'NUMBER' ||
                      action.input_type === 'CURRENCY'
                      ">
                      Multiply
                    </el-option>
                    <!-- checkboxgroup -->
                    <el-option v-if="action.input_type === 'CHECKBOX_GROUP'" value="UPDATE_PROPERTY"
                      label="Update properties">
                      Update property
                    </el-option>
                    <el-option value="CHANGE_COLOR" label="Change color">
                      Change color
                    </el-option>
                    <el-option value="empty_field" label="Empty the field"
                      v-if="action.input_type != 'SINGLE_LINE_CONTENT'">
                      Empty the field
                    </el-option>
                    <!-- checkboxgroup end -->
                  </template>
                </el-select>
                <el-select v-if="action.operator_type == 'CHANGE_COLOR'" v-model="action.property_type"
                  placeholder="Color property" size="mini" default-first-option class="ml-1 mr-1">
                  <el-option value="FONT_COLOR" label="Font color"> </el-option>
                  <el-option value="BACKGROUND_COLOR" label="Background color">
                  </el-option>
                </el-select>
                <el-color-picker size="mini" v-model="action.color" v-if="action.operator_type == 'CHANGE_COLOR' &&
                  action.property_type
                  "></el-color-picker>
                <el-select v-if="(action.operator_type == 'UPDATE' ||
                  action.operator_type == 'INCREMENT' ||
                  action.operator_type == 'DECREMENT' ||
                  action.operator_type == 'MULTIPLY' ||
                  action.operator_type == 'PUSH' ||
                  action.operator_type == 'PULL'||action.operator_type=='changelabel') &&
                  action.data_type != 'BOOLEAN'
                  " v-model="action.data_source" placeholder="Data source" size="mini" default-first-option
                  class="ml-1 mr-1">
                  <el-option v-if="action.data_type == 'OBJECT_ID'" value="ENTITY_DATA_LIST" label="ENTITY_DATA_LIST">
                  </el-option>

                  <el-option v-if="action.data_type == 'OBJECT_ID'" value="TEMPLATE" label="template">
                    Template
                  </el-option>
<template v-else-if="checkIsSelect(action) ">
    <el-option  value="field_option" label="From field">
                  </el-option>
    <el-option  value="VALUE" label="value">Value</el-option> 
</template>
                
                   


                  <template v-else-if="!checkIsSelect(action)">
                    <el-option value="VALUE" label="value">Value</el-option>
                    <el-option value="TEMPLATE" label="template">
                      Template
                    </el-option>
                    <el-option value="GLOBAL_VARIABLE" label="GLOBAL_VARIABLE" v-if="(action.input_type == 'SINGLE_LINE_TEXT' ||
                      action.input_type == 'NUMBER') &&
                      action.operator_type !== 'INCREMENT' &&
                      action.operator_type !== 'DECREMENT' &&
                      action.operator_type !== 'MULTIPLY'&& action.operator_type!=='changelabel'
                      ">
                      GLOBAL_VARIABLE
                    </el-option>

                    <el-option v-if="action.input_type == 'SINGLE_LINE_TEXT' && action.operator_type!=='changelabel'&&
                      action.data_source != -1
                      " value="login_user" label="FROM LOGIN USER"></el-option>
                    <el-option v-if="action.input_type == 'DATE'" value="TODAY" label="today">
                      Today
                    </el-option>
                    <el-option v-if="action.input_type === 'DATE_TIME'" value="TODAY" label="today">
                      Today
                    </el-option>
                    <el-option v-if="checkIsPayButtonExist(action)" value="PAYMENT" label="Payment Props">
                      Payment Props
                    </el-option>
                  </template>
                </el-select>
                <el-input v-model="action.value" size="mini" v-if="action.data_source === 'VALUE' &&
                  action.operator_type == 'changelabel' 
                  ">
                </el-input>

                <el-select v-if="action.input_type == 'YES_OR_NO' &&
                  (action.operator_type == 'UPDATE'||action.operator_type=='changelabel')
                  " v-model="action.value" size="mini"  style="margin-left: 10px;">
                  <el-option v-if="action.operator_type != 'changelabel'" value="YES" label="YES">Yes</el-option>
                  <el-option v-if="action.operator_type != 'changelabel'" value="NO" label="NO">No</el-option>
                   <el-option  v-if="action.operator_type === 'changelabel'" value="VALUE" label="value">Value</el-option> 
                </el-select>
                <el-input v-model="action.value1" size="mini" v-if="action.value === 'VALUE' && action.input_type=='YES_OR_NO' &&
                  action.operator_type == 'changelabel' 
                  ">
                </el-input>

                <!-- start -->
                <el-select v-if="action.input_type == 'CHECKBOX_GROUP' &&
                  action.operator_type == 'UPDATE_PROPERTY'
                  " v-model="action.property" size="mini">
                  <el-option value="MIN" label="min">Min</el-option>
                  <el-option value="MAX" label="max">Max</el-option>
                </el-select>

                <el-select v-if="['MIN', 'MAX'].includes(action.property)" v-model="action.data_source" size="mini">
                  <el-option value="Value" label="value">Value</el-option>
                  <el-option value="TEMPLATE" label=" From template">
                    From template
                  </el-option>
                </el-select>
                <!-- <el-input
                v-if="action.data_source === 'Value' "
                v-model="action.value"
                size="mini"
                placeholder="Enter a value"
              /> -->
                <!-- <el-select v-if="action.data_source === 'TEMPLATE' &&  action.operator_type='Make_this_field_required' && 
                  shouldShowSelect(action)
                  " v-model="action.value" size="mini">
                  <el-option v-for="(temp, i) in geTemplateFieldsByType(action)" :key="temp.key + '_' + index + '_' + i"
                    :value="temp.key" :label="temp.label">{{ temp.label }}
                  </el-option>
                </el-select> -->
                <el-select v-if="action.data_source === 'TEMPLATE' && action.operator_type !== 'Make_this_field_required' && shouldShowSelect(action)" v-model="action.value" size="mini">
  <el-option v-for="(temp, i) in geTemplateFieldsByType(action)" :key="temp.key + '_' + index + '_' + i" :value="temp.key" :label="temp.label">{{ temp.label }}</el-option>
</el-select>

                <el-select v-model="action.value" v-else-if="action.data_source == 'ENTITY_DATA_LIST'&& shouldShowSelect(action)" multiple
                  collapse-tags size="mini">
                  <el-option v-for="(temp, i) in entilistAsOptions(action)" :key="temp._id + '_' + index + '_' + i"
                    :value="temp._id" :label="getLabel(temp, i)">{{ getLabel(temp, i) }}
                  </el-option>
                </el-select>
                <el-select v-if="action.data_source === 'PAYMENT' && shouldShowSelect(action)
                  " v-model="action.value" size="mini">
                  <el-option v-for="(temp, i) in getPaymentProps(action)" :key="temp.parent_key +
                    '_info_' +
                    temp.key +
                    '_' +
                    index +
                    '_' +
                    i
                    " :value="temp.parent_key + '#' + temp.key" :label="temp.label">{{ temp.label }}
                  </el-option>
                </el-select>

                <!-- end -->
                <el-select v-else-if="action.input_type === 'CHECKBOX' &&
                 ( action.operator_type == 'UPDATE'||action.operator_type=='changelabel')
                  " v-model="action.value" size="mini"  style="margin-left: 10px;">
                  <el-option  v-if="action.operator_type != 'changelabel'"  :value="true" label="checked">checked</el-option>
                  <el-option   v-if="action.operator_type != 'changelabel'" :value="false" label="unchecked">unchecked</el-option>
                  <el-option  v-if="action.operator_type === 'changelabel'"  value="VALUE" label="value">Value</el-option> 
                </el-select>
                <el-input v-model="action.value1" size="mini" v-if="action.value === 'VALUE' && action.input_type=='CHECKBOX' &&
                  action.operator_type == 'changelabel' 
                  ">
                </el-input>

                <!-- <el-select
                v-model="action.value"
                v-else-if="
                  action.data_source == 'TEMPLATE' &&
                  action.operator_type == 'UPDATE' &&
                  action.input_type
                "
                size="mini"
              >
                <el-option
                  v-for="(temp, i) in geTemplateFieldsByType(action)"
                  :key="temp.key + '_' + index + '_' + i"
                  :value="temp.key"
                  :label="temp.label"
                  >{{ temp.label }}
                </el-option>
              </el-select> -->
                <!-- start -->
                <el-select v-if="action.input_type == 'CHECKBOX_GROUP' &&
                  action.operator_type == 'UPDATE_PROPERTY'
                  " v-model="action.property" size="mini">
                  <el-option value="MIN" label="min">Min</el-option>
                  <el-option value="MAX" label="max">Max</el-option>
                </el-select>

                <el-select v-if="['MIN', 'MAX'].includes(action.property)" v-model="action.data_source" size="mini">
                  <el-option value="Value" label="value">Value</el-option>
                  <el-option value="TEMPLATE" label=" From template">
                    From template
                  </el-option>
                </el-select>
                <!-- <el-input
                  v-if="action.data_source === 'Value'"
                  v-model="action.value"
                  size="mini"
                  placeholder="Enter a value"
                /> -->
                <!-- <el-select
                  v-if="
                    action.data_source === 'TEMPLATE' &&
                    shouldShowSelect(action)
                  "
                  v-model="action.value"
                  size="mini"
                >
                </el-select> -->
                <el-select v-model="action.value" v-else-if="action.input_type == 'LIST' &&
                  action.operator_type == 'UPDATE'
                  " size="mini" :multiple="checkIsMultiple(action)">
                  <el-option v-for="gr in filteredFields(action.selectField)" :key="gr.key + '_gr'" :value="gr.key"
                    :label="gr.label"></el-option>
                  <el-option v-for="(dt, index) of getCurrentFieldOptions(action)" :key="index + '_field_option'"
                    :label="dt.name" :value="dt">{{ dt.name }}
                  </el-option>
                </el-select>

                <el-select v-model="action.value" v-else-if="action.data_source == 'field_option' &&
                  action.input_type != 'WEEKDAYS' && action.input_type != 'CHECKBOX' && action.operator_type !== 'Make_this_field_required' &&
                  shouldShowSelect(action)
                  " collapse-tags size="mini" :multiple="checkIsMultiple(action)" placeholder="Select option" >
                  <el-option v-for="gr in filteredFields(action.selectField)" :key="gr.key + '_gr'" :value="gr.key"
                    :label="gr.label"></el-option>
                  <el-option v-for="(dt, index) of getCurrentFieldOptions(action)" :key="index + '_field_option'"
                    :label="dt" :value="dt">{{ dt }}
                  </el-option>
                </el-select>

                <!-- <el-select
                  v-model="action.value"
                  v-else-if="
                    action.data_source == 'TEMPLATE' &&
                    action.operator_type == 'UPDATE' &&
                    action.input_type
                  "
                  size="mini"
                >
                  <el-option
                    v-for="(temp, i) in geTemplateFieldsByType(action)"
                    :key="temp.key + '_' + index + '_' + i"
                    :value="temp.key"
                    :label="temp.label"
                    >{{ temp.label }}
                  </el-option>
                </el-select> -->

                <el-select v-else-if="action.data_type === 'WEEKDAYS' && action.data_source!=='VALUE'&&
                  (action.operator_type == 'UPDATE' || action.operator_type == 'PUSH' || action.operator_type == 'PULL'|| action.operator_type=='changelabel')
                  " v-model="action.value" size="mini" multiple collapse-tags>
                  <el-option v-for="(dt, index) of weekdayOptions" :key="index + '_field_option'" :label="dt.name"
                    :value="dt.value">{{ dt.name }}</el-option>
                </el-select>
                <el-select v-if="action.input_type === 'STAR_RATING' &&
                  action.data_source == 'VALUE'
                  " v-model="action.value" size="mini">
                  <el-option v-for="(dt, index) of starRating_options" :key="index + '_field_option'" :label="dt.name"
                    :value="dt.value">{{ dt.name }}</el-option>
                </el-select>
                <el-select v-else-if="action.input_type === 'RADIO' && (action.operator_type == 'UPDATE'||action.operator_type=='changelabel')"
                  v-model="action.value" size="mini"  style="margin-left: 10px;">
                  <el-option  v-if="action.operator_type != 'changelabel'"  :value="true" label="checked">checked</el-option>
                  <el-option  v-if="action.operator_type != 'changelabel'" :value="false" label="unchecked">unchecked</el-option>
                  <el-option  v-if="action.operator_type === 'changelabel'" value="VALUE" label="value">Value</el-option> 
                </el-select>
                <el-input v-model="action.value1" size="mini" v-if="action.value === 'VALUE' && action.input_type=='RADIO' &&
                  action.operator_type == 'changelabel' 
                  ">
                </el-input>

                <el-date-picker class="el-date" v-else-if="action.data_type == 'DATE' && action.data_source == 'VALUE' && action.operator_type!='changelabel'
                  " v-model="action.value" type="date" placeholder="Pick a day" size="mini" :picker-options="pickerOptions"></el-date-picker>

<el-time-picker 
  v-model="action.value" 
  size="mini" 
  v-else-if="action.data_source === 'VALUE' &&
             action.data_type === 'TIME' &&
             action.operator_type === 'UPDATE' &&
             shouldShowSelect(action)"
  :picker-options="dataHasTimeSettings()">
</el-time-picker>
<el-date-picker v-else-if="action.data_source === 'VALUE' &&
                  action.data_type === 'DATE_TIME' &&
                  action.operator_type == 'UPDATE'
                  " v-model="action.value" type="datetime" size="mini" placeholder="Select date and time">
                </el-date-picker>
                <el-date-picker v-else-if="action.data_source === 'VALUE' &&
                  action.data_type === 'DATE_RANGE' &&
                  action.operator_type == 'UPDATE'
                  " size="mini" v-model="action.value" type="daterange" range-separator="To"
                  start-placeholder="Start date" end-placeholder="End date"></el-date-picker>
                <el-date-picker v-else-if="action.data_source === 'VALUE' &&
                  action.data_type === 'DATE_TIME_RANGE' &&
                  action.operator_type == 'UPDATE'
                  " v-model="action.value" type="datetimerange" range-separator="To" start-placeholder="Start date"
                  end-placeholder="End date" size="mini">
                </el-date-picker>
                <el-time-picker
                v-else-if="action.input_type=== 'TIME_RANGE' && action.operator_type=='UPDATE' && action.data_source === 'VALUE'"
      v-model="action.value"
      is-range
      range-separator="To"
      start-placeholder="Start time"
      end-placeholder="End time"
      size="mini"
    />
                <!-- <el-select
                  v-model="action.value"
                  v-else-if="
                    action.data_source == 'field_option' &&
                    action.operator_type == 'UPDATE'
                  "
                  collapse-tags
                  size="mini"
                  :multiple="checkIsMultiple(action)"
                >
                  <el-option
                    v-for="(dt, index) of getCurrentFieldOptions(action)"
                    :key="index + 'action_field_option'"
                    :label="dt"
                    :value="dt"
                    >{{ dt }}
                  </el-option>
                </el-select> -->

                <!-- <el-input-number
                v-model="action.value"
                v-else-if="
                  action.data_source == 'VALUE' &&
                  action.input_type == 'NUMBER' &&
                  action.operator_type == 'UPDATE'
                "
                :controls="false"
                size="mini"
                
              ></el-input-number> -->

                <el-select v-if="action.data_source == 'GLOBAL_VARIABLE'" v-model="action.value" size="mini"
                  no-data-text="No Global Variables with Field" @change="(value) => addGlobalVaraibleData(value, action)">
                  <el-option v-for="(gbVab, index) of selectedGlobalVariablesList(
                    action
                  )" :key="index" :label="`${gbVab.label} -> ${gbVab.value ? gbVab.value : ''
  }`" :value="gbVab._id"></el-option>
                </el-select>
                <el-select v-model="action.value" size="mini" v-if="action.data_source == 'login_user' &&
                  action.operator_type == 'UPDATE'
                  " @change="(changedValue) =>
    checkCurrentFieldValue(changedValue, action)
    ">
                  <el-option v-for="(op, index) in loginUserFields" :key="index + op.value" :label="op.label"
                    :value="op.value">{{ op.value }}</el-option>
                </el-select>
                <el-input v-else-if="action.input_type !== 'ENTITY' &&
                  (action.operator_type == 'UPDATE' ||
                    action.operator_type == 'INCREMENT' ||
                    action.operator_type == 'DECREMENT' ||
                    action.operator_type == 'MULTIPLY') &&
                  action.data_type !== 'BOOLEAN' &&
                  action.data_type !== 'DATE' &&
                  action.input_type !== 'LIST' &&
                  action.data_source !== 'PAYMENT' &&
                  action.data_source !== 'field_option' &&
                  action.data_source !== 'TEMPLATE' &&
                  action.data_source !== 'GLOBAL_VARIABLE' &&
                  action.input_type !== 'TIME' &&
                  action.input_type !== 'DATE_TIME' && action.input_type !== 'DATE_RANGE'&&  action.input_type !== 'TIME_RANGE' &&  action.input_type !== 'DATE_TIME_RANGE' 
                  " :disabled="!action.data_source" v-model="action.value" placeholder="Value" size="mini"
                  class="ml-1">
                </el-input>
              </template>
            </div>
            <el-link class="mr-1" type="primary" :underline="false" @click="addNewAction(false)"><i
                class="el-icon-circle-plus"></i>
            </el-link>
            <el-link class="mr-1" type="danger" :underline="false" @click="deleteAction(index)"
              :disabled="currentRule.actions.length == 1"><i class="el-icon-remove"></i>
            </el-link>
          </div>
        </el-scrollbar>
      </div>
      <div class="action-footer">
        <el-button size="mini" @click="cancelNewRule">Cancel</el-button>
        <el-button type="primary" size="mini" :disabled="checkSaveDisabled" @click="saveNewRule">Save</el-button>
      </div>
    </div>
    <div v-else-if="!templateRules.length" class="empty-alert">
      <div>
        <img src="@/assets/img/nodata2.svg" />
      </div>
      <div>
        <el-button type="danger" icon="el-icon-plus" plain @click="addNewCondition(true)" class="add-rule" size="mini">Add
          rule</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
import { bus } from "../../main";
export default {
  props: ["allFields", "templateRules", "checkBoxGroupArray",  "fromDataTable","data"],

  mixins: [TemplateBuilderHelper],
  async mounted() {
    this.getCompanyUsersList();
    await this.fetchGlobalVariables();
    await this.fetchEntityVariableData();
    await this.$store.dispatch("globalVariables/fetchGlobalVariables", {
      get_all: true,
    });
    await this.$store.dispatch(
      "auth/getUserTypeList",
      { companyId: this.getAuthenticatedUser.current_workspace.company_id }
    );
    // await Promise.all([
    //     this.$store.dispatch("companyTemplates/getAllCompanyTemplates", {
    //       get_all: true,
    //       include_standard: true,
    //     }),
    //     this.$store.dispatch("entities/fetchEntities", {
    //       get_all: true,
    //       include_standard: true,
    //     }),

    //   ]);
    let entityRuels = [];
    await this.fetchEntityVariableData();
    console.log("templateRules", this.templateRules);

    if (this.templateRules && this.templateRules.length) {
      this.templateRules.forEach((rule) => {
        if (rule && rule.actions) {
          rule.conditions.forEach((element) => {
            if (
              element &&
              element.data_source == "ENTITY_DATA_LIST" &&
              element.relation_entity_id
            ) {
              entityRuels.push(element.relation_entity_id);
            }
          });
          rule.actions.forEach((element) => {
            if (
              element &&
              element.data_source == "ENTITY_DATA_LIST" &&
              element.relation_entity_id
            ) {
              entityRuels.push(element.relation_entity_id);
            }
            if (element.selectField) {
              this.checkFormTemplateFields(element.selectField);
            }
          });
        }
      });
    }
    if (this.templateRules && this.templateRules.length) {
      this.templateRules.forEach((rule) => {
        if (rule && rule.conditions) {
          rule.actions.forEach((element) => {
            if (
              element &&
              element.data_source == "ENTITY_DATA_LIST" &&
              element.relation_entity_id
            ) {
              entityRuels.push(element.relation_entity_id);
            }
            if (element.selectField) {
              this.checkFormTemplateFields(element.selectField);
            }
          });
        }
      });
    }

    if (entityRuels && entityRuels.length) {
      this.loading = true;
      await Promise.all(
        entityRuels.map(async (e) => {
          return await this.fetchEntityPrimaryFieldsData(e);
        })
      )
      this.loading = false;
    }
    [this.formbuildersList] = await Promise.all([
      this.fetchAllFormbuilders(),
      this.$store.dispatch("companyTemplates/getAllCompanyTemplatesData", {
        get_all: true,
        include_standard: true,
      }),

      this.$store.dispatch("entities/fetchEntities", {
        get_all: true,
        include_standard: true,
      }),
    ]);
    await Promise.all([
      this.$store.dispatch("companyTemplates/getAllCompanyTemplates", {
        get_all: true,
        populate_data: true,
      }),
      this.$store.dispatch("globalVariables/fetchGlobalVariables", {
        get_all: true,
        company_id: this.company_id,
      }),
    ]);
    await this.fetchEntityVariableData();

  },
  computed: {
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    ...mapGetters("auth", [
      "getDefaultDateFormat",
      "getAuthenticatedUser",
      "getUserTypeList",
      "getUserType","getCompanyUsers",
    ]),
    ...mapGetters("users", ["getUsersList"]),
    ...mapGetters("entities", ["getEntityRecordsForTable", "getAllEntities"]),
    ...mapGetters("companyTemplates", ["getSingleCompanyTemplate", "getAllCompanyTemplatesData", "getAllCompanyTemplates"]),

    entilistAsOptions() {
      return (filter) => {
        let field = this.getField(filter.selectField);
        if (
          field &&
          field.entity_id &&
          this.entityDataInfo &&
          this.entityDataInfo.length
        ) {
          let selectedEntity = this.entityDataInfo.find(
            (e) => e.entity_id === field.entity_id
          );
          if (selectedEntity && selectedEntity.data) {
            return selectedEntity.data;
          }
        } else {
          return [];
        }
      };
    },
    filteredRules() {
      if (!this.searchQuery) {
        return this.templateRules;
      } else {
        return this.templateRules.filter(rule => 
          rule.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
    },
    pickerOptions() {
    const data = this.allFields.filter(field => {
    return field.data_type === 'DATE' && field.input_type === 'DATE' && this.selectedFieldsKey==field.key;
  });
if (
      data.length>0 &&
      data[0].properties &&
      data[0].properties.date_settings &&
      data[0].properties.date_settings.toLowerCase() === "past dates"&&
      data[0].properties.selected_weekdays &&this.selectedFieldsKey==data[0].key
    ) {
      const selectedWeekdays = data[0].properties.selected_weekdays;
      return {
        disabledDate: (date) => {
          const currentDate = new Date();
          if (selectedWeekdays.length > 0) {
            return (
              date.getTime() >= currentDate.getTime() ||
              !selectedWeekdays.includes(date.getDay())
            );
          } else {
            return date.getTime() >= currentDate.getTime();
          }
        },
      };
    } else if (
    data.length>0 &&
    data[0].properties &&
    data[0].properties.date_settings &&
    data[0].properties.date_settings === "Future Dates" &&
    data[0].properties.selected_weekdays&&this.selectedFieldsKey==data[0].key
  ) {
    const selectedWeekdays = data[0].properties.selected_weekdays;
    return {
      disabledDate: (date) => {
        const currentDate = new Date();
        if (selectedWeekdays.length > 0) {
        return (
          date.getTime() < currentDate.getTime() ||
          !selectedWeekdays.includes(date.getDay())
        );
      } else {
        return date.getTime() < currentDate.getTime();
      }
      },
    };
  }  else if (
    data.length>0 &&
    data[0].properties &&
    data[0].properties.date_settings &&
    data[0].properties.date_settings === "WeekDays"&&this.selectedFieldsKey==data[0].key
  ) {
    let selectedWeekdays = data[0].properties.selected_weekdays;
    return {
      disabledDate: (date) => {
        const day = date.getDay();
        return !selectedWeekdays.includes(day);
      },
    };
  } else if (
        data.length>0 &&
        data[0].properties &&
        data[0].properties.date_settings &&
        data[0].properties.date_settings === "Range"  &&
        data[0].properties.selected_weekdays&& this.selectedFieldsKey==data[0].key
      ) {
        let start = data[0].properties.date_strat_range;
        let end = data[0].properties.date_end_range;
        const selectedWeekdays = data[0].properties.selected_weekdays;
        return {
          disabledDate(date) {
            if (start && end) {
              if (selectedWeekdays.length > 0) {
              return !(
                date.getTime() > new Date(start).getTime() &&
                date.getTime() < new Date(end).getTime() && selectedWeekdays.includes(date.getDay())
              );
            }else{
              return !(
                date.getTime() > new Date(start).getTime() &&
                date.getTime() < new Date(end).getTime())
            }}
            return false;
          },
        };
      }
  else {
    return {};
  }
},
getUserTypeModel: {
    get() {
      return this.userTypeModel;
    },
    set(value) {
      this.userTypeModel = value;
      // If you need to do something when the value changes, you can do it here.
    },
  },
    filteredEntities() {
      if (this.getAllEntities && this.getAllEntities.data) {
        return this.getAllEntities.data.filter(user => user.entity_type === 'BUSINESS' || user.entity_type === 'INDIVIDUAL');
      } else {
        return [];
      }
    },
    todayDate() {
      const currentDate = new Date();
      return currentDate.toDateString();

    },
    NumberFields() {
      return this.allFields.filter(field => field.data_type === 'NUMBER' || (field.input_type=='FORMULA' && field.result_type === 'NUMBER')|| (field.input_type=='AGGREGATE_FUNCTION')||field.input_type=='ENTITY_VARIABLE'&& field.inputType === 'NUMBER');
    },
    checkSaveDisabled() {
      if (
        !this.currentRule.name ||
        !this.currentRule.conditions.length ||
        !this.currentRule.actions.length
      ) {
        return true;
      } else if (
        this.currentRule.conditions.length ||
        this.currentRule.actions.length
      ) {
        let notConfiguredRule = this.currentRule.conditions.find((e) => {
          if (
            e &&
            e.selectField &&
            e.selectField == "apply_rules" &&
            e.value != ""
          ) {
            return false;
          } else if (!e || !e.selectField || !e.operator) {
            return true;
          }
        });

        let notConfiguredAction = this.currentRule.actions.find((e) => {
          console.log("e", e);
          if (!e || !e.selectField) {
            return true;
          } else if (e.selectField == "NOTIFICATION") {
            return !e.notify_type || !e.message;
          } else if (e.operator_type == "CHANGE_COLOR") {
            return !e.property_type || !e.color;
          }
          // else if(e.selectField==="number") {
          //   return (
          //     !e.operator_type ||
          //     (e.operator_type == "UPDATE"  && e.data_source!="" && e.value !="" && !isNaN(parseFloat(e.value)))
          //   );
          // }
          else {
            return (
              !e.operator_type ||
              (e.operator_type == "UPDATE" &&
                e.operator_type &&
                e.data_type != "NUMBER" &&
                e.data_source != "" &&e.data_source!='TODAY'&& 
                !e.value)
            );
          }
        });
        if (notConfiguredRule || notConfiguredAction) {
          return true;
        }
      }
      return false;
    },
    includeDataTableFields() {
      let datatableFields = [],
        fields = this.allowedFields;
      fields.forEach((e) => {
        if ((e.inputType == "DATA_TABLE" || e.inputType == 'ENTITY_TABLE') && e.data_table_columns) {
          e.data_table_columns.forEach((j) => {
            if (this.allowedFieldTypes.indexOf(j.inputType) !== -1) {
              let c = JSON.parse(JSON.stringify(j));
              if (c.key && !c.key.includes("#")) {
                c.key = e.key + "#" + c.key;
                c.label = e.label + " - " + c.label;
              }
              else if (c.key && c.key.includes('@')) {
                c.label = e.label + " - " + c.label;
              }
              datatableFields.push(c);
            }
          });
        }
        return e;
      });
      return [...fields, ...datatableFields];
    },
    allowedFields() {
  let groups = [];

  this.allFields.forEach((gr) => {
    if (gr?.group_key && gr.input_type === "CHECKBOX") {
      let group = (this.checkBoxGroupArray || []).find(
        (e) => e.key === gr.group_key
      );

      if (group && !groups.some(el => el.key === group.key)) {
        groups.push({
          ...group,
          inputType: "CHECKBOX_GROUP",
          is_individual: true,
        });
      }
    }
  });

  let filteredFields = this.allFields.filter((e) => e.inputType !== "FILE");

  return [
    ...groups,
    ...this.allFields.filter((e) => {
      if (
        (!e.group_key && (this.allowedFieldTypes.includes(e.inputType) || this.isValidActionButton(e))) &&
        (filteredFields || (e.key && e.label))
      ) {
        return true;
      }
    }),
  ];
},

    allowedContentFields() {
      let groups = [];

      this.allFields.forEach((gr) => {
        if (gr?.group_key && gr.inputType == "CHECKBOX") {
          let group = (this.checkBoxGroupArray || []).find(
            (e) => e.key == gr.group_key
          );

          if (group?.key) {
            let exIndex = groups.findIndex((el) => el.key == group.key);
            console.log("exIndex", exIndex);
            if (exIndex === -1) {
              groups.push({
                ...group,
                ...{ inputType: "CHECKBOX_GROUP", is_individual: true },
              });
              console.log(group);
            }
          }
        }
      });
      return [
        ...groups,
        ...this.allFields.filter((e) => {
          if (
  !e.group_key &&
  (this.allowedContentFieldTypes.indexOf(e.inputType) !== -1 ||
    (this.isValidActionButton(e) && e.inputType !== 'ACTION_BUTTON'))
) {
            return true;
          }
        }),
      ];
    },
  },
  methods: {
    clearSearch() {
      this.searchQuery = '';
    },
    showInfo(index) {
      let dt = JSON.parse(JSON.stringify(this.templateRules[index]));
      this.currentRule.description=dt.description
      this.descriptionVisible = !this.descriptionVisible;
    },
   dataHasTimeSettings() {
    const data = this.allFields.filter(field => {
        return field.properties && field.properties.time_start_range && field.properties.time_end_range && this.selectedFieldsKey==field.key;
    });
    if (data.length > 0) {
      for (const field of data) {
      if (field.properties.time_settings === 'Range' ) {
            const additionalOptions = this.timePicker(field);
            return {
                    selectableRange: `${additionalOptions.startTime} - ${additionalOptions.endTime}`,
                    format: 'HH:mm:ss',
                    ...additionalOptions,
                };
            }
        }
        return {
            format: 'HH:mm:ss',
        };
    } else {
        return {
            format: 'HH:mm:ss',
        };
    }
},
timePicker(field) {
    const data = field;
    const utcStartTime = new Date(data?.properties?.time_start_range);
    const utcEndTime = new Date(data?.properties?.time_end_range);
    const istStartTime = utcStartTime?.toLocaleTimeString('en-US', { timeZone: 'Asia/Kolkata', hour12: false });
    const istEndTime = utcEndTime?.toLocaleTimeString('en-US', { timeZone: 'Asia/Kolkata', hour12: false });

    return {
        startTime: istStartTime,
        endTime: istEndTime,
    };
},
 async getCompanyUsersList() {
      try {
        await this.$store.dispatch(
          "auth/getCompanyUsersList",
          this.getAuthenticatedUser.current_workspace.company_id
        );
        this.users = this.$store.getters["auth/getCompanyUsers"];
        
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: err,
        });
      }
    },
    async fetchEntityVariableData() {
      if (this.templateRules && this.templateRules.length) {
        let templateIds = [];
        this.templateRules.map((e) => {
          if (e?.conditions && e.conditions.length) {
            e.conditions.forEach((f) => {
              let field = this.getField(f.selectField);
              console.log("field", field);
              if (field?.input_type == "ENTITY_VARIABLE") {
                let tempId = "";
                if (field.global_variable_entity_field.includes("#")) {
                  tempId = field.global_variable_entity_field.split("#")[0];
                } else if (field.global_variable_entity_field.includes("~")) {
                  tempId = field.global_variable_entity_field.split("~")[0];
                } else if (field.global_variable_entity_field_template_id) {
                  tempId = field.global_variable_entity_field_template_id;
                }
                if (tempId) {
                  templateIds.push(tempId);
                }
              }
            });
          }
        });
        if (templateIds && templateIds.length) {
          await templateIds.map(async (f) => {
            return await this.fetchTemplateInfo(f);
          });
        }
      }
    },
    truncateContent(content, limit) {
      return content ? content.slice(0, limit) : '';
    },
    getCurrentDateTime() {
      const currentDate = new Date();
      const currentDateTimeString = currentDate.toLocaleString();
      return currentDateTimeString;
    },

    addGlobalVaraibleData(value, rule) {
      let selectedGlobalVariable = this.selectedGlobalVariablesList(rule).find(
        (e) => e._id == value
      );
      if (selectedGlobalVariable?.value) {
        rule.value = selectedGlobalVariable.value;
      }
    },
    selectedGlobalVariablesList(rule) {
      let data = this.globalVariablesList.filter(
        (x) => x && x.input_type == rule.input_type
      );
      console.log("selcted data", data);
      return data;
    },
    isValidActionButton(field) {
      if (field?.actions) {
        let allowedField = (field.actions || []).find(
          (e) => e.action_button_action_type == "TEMPLATE_ACTION"
        );
        if (allowedField) {
          return true;
        }
      }
      return false;
    },
    filteredFields(selectedGroupKey) {
      console.log("-=-=-=-=-=-", selectedGroupKey, this.allFields);
      return this.allFields.filter(
        (gr) => gr.inputType === "CHECKBOX" && selectedGroupKey == gr.group_key
      );
    },
 

    geTemplateFieldsByType(rule) {
      let field = this.getField(rule.selectField);
      if (field.input_type) {
        return [this.allFields, ...this.includeDataTableFields].filter((e) => {
          if (field.input_type === "CHECKBOX_GROUP") {
            return e.inputType === "NUMBER";
          } else if (field.input_type === "NUMBER") {
            return (
              (e.inputType === "NUMBER" ||
                e.inputType === "AGGREGATE_FUNCTION" ||
                e.inputType === "FORMULA" ||
                e.input_type === "CURRENCY") &&
              e.key !== rule.selectField
            );
          }
          else if (
            field.input_type === "EMAIL" ||
            field.input_type === "PASSWORD"
          ) {
            return (
              e.input_type === "SINGLE_LINE_TEXT" ||
              (e.input_type === "EMAIL" && e.key !== rule.selectField)
            );
          }
          else if (
            field.input_type === "HYPER_LINK"
          ) {
            return (
              (e.input_type === "HYPER_LINK" && e.key !== rule.selectField)
            );
          }
          else if (
            field.input_type === "DATE"
          ) {
            return (
              (e.input_type === "DATE" || e.result_type=='DATE' ||(e.input_type=='ENTITY_VARIABLE' && e.inputType=='DATE')) && e.key !== rule.selectField
            );
          }
          else if (field.inputType === "AGGREGATE_FUNCTION") {
            return (
              e.inputType === "CURRENCY" ||
              e.inputType === "FORMULA" ||
              e.inputType === "NUMBER" ||
              (e.inputType === "AGGREGATE_FUNCTION" &&
                e.key !== rule.selectField)
            );
          } else if (field.input_type === "CURRENCY") {
            return (
              e.inputType === "AGGREGATE_FUNCTION" ||
              e.inputType === "FORMULA" ||
              e.input_type === "NUMBER" || (e.input_type=='ENTITY_VARIABLE' && e.inputType=='CURRENCY')||
              (e.input_type === "CURRENCY" && e.key !== rule.selectField)
            );
          }
          else if (field.input_type === "FORMULA") {
            let numberTypes = ["CURRENCY", "AGGREGATE_FUNCTION", "NUMBER"];
            return (
              (numberTypes.indexOf(field.result_type) !== -1 &&
                (numberTypes.indexOf(e.input_type) !== -1 || (e.input_type === "FORMULA" && numberTypes.indexOf(e.result_type) !== -1))) || field.result_type == e.inputType || (e.inputType == "FORMULA" && field.result_type == e.result_type)
            ) && e.key !== rule.selectField;
          }
          else {
            if (field.inputType == "FORMULA") {
              return (
                e.key !== field.key &&
                (field.result_type == e.inputType ||
                  (e.inputType == "FORMULA" &&
                    field.result_type == e.result_type))
              );
            }
            else if (field.input_type === "ENTITY_VARIABLE") {

              return e.inputType === field.inputType && e.key !== field.key;
            }
            else if (field.input_type === "PAYMENT_VARIABLE") {
              return e.inputType === field.inputType && e.key !== field.key;
            } else if (field.input_type === "PAY_BUTTON") {
              return e.inputType === field.inputType && e.key !== field.key;
            } else {
              return e.inputType === field.input_type && e.key !== field.key;
            }
          }
        });
      }
      return [];
    },
    getPaymentProps(rule) {
      let payButtonExist = this.lodash.filter(
        this.currentRule.conditions,
        function (element) {
          return element.input_type === "PAY_BUTTON";
        }
      );
      if (payButtonExist && payButtonExist.length) {
        let paymentFields = [
          { label: "Transaction Id", key: "txn_id", input_type: "STRING" },
          { label: "Gateway", key: "gateway", input_type: "STRING" },
          { label: "Name", key: "name", input_type: "STRING" },
          { label: "Email", key: "email", input_type: "STRING" },
          { label: "Phone Number", key: "phoneNumber", input_type: "NUMBER" },
          { label: "Payment Type", key: "paymentType", input_type: "STRING" },
          { label: "Method", key: "method", input_type: "STRING" },
          { label: "Currency", key: "currency", input_type: "STRING" },
          { label: "Amount", key: "amount", input_type: "NUMBER" },
          { label: "Payment Date", key: "paymentDate", input_type: "DATE" },
          { label: "Status", key: "status", input_type: "STRING" },
          {
            label: "Payment Session",
            key: "paymentSession",
            input_type: "STRING",
          },
          {
            label: "Amount Refunded",
            key: "amountRefunded",
            input_type: "NUMBER",
          },
          { label: "Description", key: "description", input_type: "STRING" },
          { label: "Refund Date", key: "refundDate", input_type: "DATE" },
        ];
        let fields = [];
        payButtonExist.forEach((condition) => {
          console.log(condition);
          paymentFields.forEach((payField) => {
            if (
              (rule.data_type == "DATE_TIME" || rule.data_type == "DATE") &&
              payField.input_type == "DATE"
            ) {
              fields.push({ ...payField, parent_key: condition.selectField });
            } else if (rule.data_type == payField.input_type) {
              fields.push({ ...payField, parent_key: condition.selectField });
            }
          });
        });
        return fields;
      }

      return [];
    },

    getLabel(item, i) {
      let selectedEntity = (this.entityDataInfo || []).find(
        (e) => e.entity_id == item.entity_id
      );
      if (selectedEntity && selectedEntity.entity) {
        selectedEntity = selectedEntity.entity;
      }
      let name =
        item &&
          item.entity_prime_data &&
          Object.keys(item.entity_prime_data) &&
          item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          : "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          if (item.entity_prime_data && item.entity_prime_data[key]) {
            name = item.entity_prime_data[key + "/name"]
              ? item.entity_prime_data[key + "/name"]
              : item.entity_prime_data[key];
          } else {
            name =
              item.entityData &&
                primaryField.template_id &&
                item.entityData[primaryField.template_id]
                ? item.entityData[primaryField.template_id][key + "/name"]
                  ? item.entityData[primaryField.template_id][key + "/name"]
                  : item.entityData[primaryField.template_id][key]
                    ? item.entityData[primaryField.template_id][key]
                    : "data" + i
                : "data" + i;
          }
        }
      }
      return name;
    },
    getFieldOperator(con) {
      return con.operator;
    },
    getFieldLabel(key) {
      let field = this.getField(key);
      if (field?.label) {
        return field.label;
      }
      return key;
    },
    fetchFieldValue(con) {
  if (con && con.value) {
    if (con.input_type === 'DATE') {
      return new Date(con.value).toLocaleDateString();
    }
    return con.value;
  } else if ((con.data_type === 'NUMBER' || con.input_type === 'FORMULA') && con.startValue && con.endValue) {
    return `${con.startValue} to ${con.endValue}`;
  } 
  return 'N/A';
},

    shouldShowSelect(action) {
      return (
        action.operator_type !== "HIDE" &&
        action.operator_type !== "SHOW" &&
        action.operator_type !== "DISABLE" 
      );
    },

    checkIsMultiple(filter) {
      if (filter.selectField) {
        let field = this.getField(filter.selectField);
        if(field && field.inputType == 'WEEKDAYS'){
          return field.allow_multiple
        }
        if (field && field.input_type == "SELECT") {
          return true;
        }
        if (field && field.input_type == "PAY_BUTTON") {
          return false;
        } else if (
          field?.input_type == "ENTITY_VARIABLE" &&
          this.templateDataInfo
        ) {
          let tempId = "";
          let key = "";
          if (field.global_variable_entity_field.includes("#")) {
            [tempId, key] = field.global_variable_entity_field.split("#");
          } else if (field.global_variable_entity_field.includes("~")) {
            tempId = field.global_variable_entity_field.split("~")[0];
            if (field.global_variable_entity_field.split("~")[1]) {
              key = field.global_variable_entity_field.split("~")[1];
            }
          } else if (field.global_variable_entity_field_template_id) {
            tempId = field.global_variable_entity_field_template_id;
          }
          if (field.global_variable_entity_select_type_field_key) {
            key = field.global_variable_entity_select_type_field_key;
          }
          if (this.templateDataInfo[tempId] && key) {
            let originField = this.templateDataInfo[tempId].find(
              (el) => el.key == key
            );
            if (originField?.inputType == "SELECT") {
              return false;
            }
          }
          return true;
        }
        return true;
      }
      return true;
    },

    getCurrentFieldOptions(filter) {
      if (filter.selectField === "userType") {
        filter.data_type = "userType";
        filter.input_type = "userType";
        return
      }
      if (filter.selectField === "IsUserType") {
        filter.data_type = "IsUserType";
        filter.input_type = "IsUserType";
        return [
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
        ];
      }
      if (filter.selectField) {
        let field = this.getField(filter.selectField);
        if (field.inputType == "LIST" && field.list_data) {
          return field.list_data;
        }
        if (field && field.options) {
          return field.options;
        }
        else if (
          field?.input_type == "ENTITY_VARIABLE" &&
          this.templateDataInfo
        ) {
          let tempId = "";
          let key = "";
          if (field.global_variable_entity_field.includes("#")) {
            [tempId, key] = field.global_variable_entity_field.split("#");
          } else if (field.global_variable_entity_field.includes("~")) {
            tempId = field.global_variable_entity_field.split("~")[0];
            if (field.global_variable_entity_field.split("~")[1]) {
              key = field.global_variable_entity_field.split("~")[1];
            }
          } else if (field.global_variable_entity_field_template_id) {
            tempId = field.global_variable_entity_field_template_id;
          }
          if (field.global_variable_entity_select_type_field_key) {
            key = field.global_variable_entity_select_type_field_key;
          }
          if (this.templateDataInfo[tempId] && key) {
            let originField = this.templateDataInfo[tempId].find(
              (el) => el.key == key
            );
            if (originField?.options) {
              return originField.options;
            }
          }
          return [];
        }
        if (field && field.input_type == "PAY_BUTTON" && field.pay_status) {
          return field.pay_status;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    checkActionExisted(op) {
      if (op) {
        return (this.currentRule.actions || []).find(
          (field) => field.selectField == op.key
        ) ||
          (this.currentRule.conditions || []).find(
            (e) => e.selectField == op.key
          )
          ? true
          : false;
      }
      return false;
    },
    cancelNewRule() {
      this.resetCurrentRule();
    },
    addNewRule() {
      this.resetCurrentRule();
      this.currentRuleIndex = -1;
      this.addNewCondition(true);
      this.addNewAction(true);
    },
    editRule(index) {
      let dt = JSON.parse(JSON.stringify(this.templateRules[index]));
      this.currentRule.name = dt.name;
      this.currentRule.description=dt.description
      this.currentRule.isRuleActive=dt.isRuleActive
      this.currentRule.query_type = dt.query_type;
      this.currentRule.conditions = dt.conditions;
      this.currentRule.actions = dt.actions;
      this.currentRule["when"] = dt.when ? dt.when : "IMMEDIATELY";
      this.currentRule["show_alert"] = dt.show_alert ? dt.show_alert : false;
      this.currentRule["alert_message"] = dt.alert_message || "";
      this.currentRuleIndex = index;
      this.addOrEditingRules = true;
      this.currentRule["apply_alert_on"] = dt.apply_alert_on
        ? dt.apply_alert_on
        : "both";

    },
    CopyRule(index) {
      this.addNewRule();
      let dt = JSON.parse(JSON.stringify(this.templateRules[index]));
      // this.currentRule.name = dt.name;
      this.currentRule.query_type = dt.query_type;
      this.currentRule.conditions = dt.conditions;
     this.currentRule.actions = dt.actions;
      this.currentRule["when"] = dt.when ? dt.when : "IMMEDIATELY";
      this.currentRule["show_alert"] = dt.show_alert ? dt.show_alert : false;
      this.currentRule["alert_message"] = dt.alert_message || "";
      this.currentRuleIndex = this.templateRules.indexOf(this.currentRule);
      this.addOrEditingRules = true;
    },

    deleteRule(index) {
      console.log("Deleting rule at index", index);
      this.addOrEditingRules = false;
      this.$emit("form-rule-delete", {
        index: index,
      });

      if (!this.templateRules.length) {
        this.resetCurrentRule();
      }
    },

    checkRuleName(event) {
      let ruleNameCheck = this.templateRules.filter(
        (el) => el && el.name === event
      );
      console.log("ruleNameCheck", event, ruleNameCheck);
      if (ruleNameCheck && ruleNameCheck.length) {
        this.$message.error("Rule name already exists!");
        this.currentRule.name = "";
      } else if (/^\s*$/.test(event)) {
        this.$message.error(
          "Rule name should not be empty or contain only spaces!"
        );
        this.currentRule.name = "";
      }
    },
    saveNewRule() {
      console.log("calling this method", this.currentRule, this.templateRules);
      console.log(
        "data",
        this.getAllEntities, this.getUserTypeList, this.getAuthenticatedUser
      );
      this.addOrEditingRules = false;
      this.$emit("form-rule-added", {
        ...this.currentRule,
        ...{ index: this.currentRuleIndex },
      });

      this.resetActionForm();
      this.resetFormRule();
      // }
    },
    // async getCompanyUserTypeList() {
    //   try {
    //     await this.$store.dispatch("auth/getUserTypeList",{companyId:this.getActiveWorkspace.company_id});
    //     if (this.getUserTypeList) {
    //       this.userType = this.getUserTypeList;
    //     }
    //   } catch (err) {
    //     // console.log(err);
    //     this.$notify.error({
    //       title: "Error",
    //       message: err,
    //     });
    //   }
    // },
    resetCurrentRule() {
      this.currentRule.name = "";
      this.currentRule.description="";
      this.currentRule.query_type = "AND";
      this.currentRule.conditions = [];
      this.currentRule.actions = [];
      this.addOrEditingRules = false;
      this.currentRule = {
        ...this.currentRule,
        ...{
          name: "",
          description:"",
          isRuleActive:true,
          query_type: "AND",
          conditions: [],
          actions: [],
          apply_alert_on: "both",
        },
      };
      //   //this.currentRule.apply_alert_on = 'both';
    },
    // resetCurrentRule() {
    //   this.currentRule.name = "";
    //   this.currentRule.query_type = "AND";
    //   this.currentRule.conditions = [];
    //   this.currentRule.actions = [];
    //   this.addOrEditingRules = false;
    // },
    resetFormRule() {
      this.formRule.selectField = "";
      this.formRule.operator = "";
      this.formRule.data_source = "";
      this.formRule.value = "";
      // this.formRule.value1="";
    },
    resetActionForm() {
      this.actionForm.selectField = "";
      this.actionForm.operator_type = "";
      this.actionForm.data_source = "";
      this.actionForm.value = "";
    },
    deleteCondition(index) {
      if (this.currentRule?.conditions) {
        this.currentRule.conditions.splice(index, 1);
        if (this.currentRule.conditions.length == 0) {
          this.currentRule.actions = [];
        }
      }
    },
    deleteAction(index) {
      this.currentRule.actions.splice(index, 1);
    },
    getTypeFromInputType(inputType) {
      const obj = {
        SINGLE_LINE_TEXT: "STRING",
        CAPTCHA:"CAPTCHA",
        CONCATENATE: 'STRING',
        MULTI_LINE_TEXT: "STRING",
        SINGLE_LINE_CONTENT: "STRING",
        DATE: "DATE",
        DATE_TIME: "DATE_TIME",
        TIME: "TIME",
        NUMBER: "NUMBER",
        AGGREGATE_FUNCTION: "NUMBER",
        FILE: "FILE",
        SELECT: "ARRAY",
        YES_OR_NO: "BOOLEAN",
        MULTI_SELECT: "ARRAY",
        CHECKBOX_GROUP: "ARRAY",
        RADIO_BUTTON_GROUP: "ARRAY",
        CHECKBOX: "BOOLEAN",
        RADIO: "BOOLEAN",
        LIST: "ARRAY",
        VIDEO:"VIDEO",
        HEADING: "STRING",
        SIGNATURE: "SIGNATURE",
        GLOBAL_VARIABLE: "OBJECT_ID",
        ENTITY: "OBJECT_ID",
        IMAGE: "IMAGE",
        PASSWORD: "STRING",
        MASKED: "STRING",
        EMAIL: "EMAIL",
        PHONE: "NUMBER",
        FAX: "NUMBER",
        CURRENCY: "NUMBER",
        PHONE_COUNTRY_CODE: "PHONE",
        QUESTION: "QUESTION",
        DATE_TIME_RANGE: "DATE_TIME_RANGE",
        DATE_RANGE: "DATE_RANGE",
        WEEKDAYS: "WEEKDAYS",
        TIME_RANGE: "TIME_RANGE",
        ACTION_BUTTON: "ACTION",
        HYPER_LINK: "HYPER_LINK",
        STAR_RATING: "NUMBER",
        PAY_BUTTON: "PAYMENT",
        DATA_TABLE: "TABLE",
        AUTO_INCREMENT_NUMBER: "STRING",
      };

      return obj[inputType];
    },
    getField(fieldKey) {
      let field;
      //check this field is data table field or not
      if (fieldKey && fieldKey.includes("#")) {
        field = this.includeDataTableFields.find(
          (field) => field.key == fieldKey
        );
      } else {
        field = [...this.allowedFields, ...this.allFields].find(
          (field) => field.key == fieldKey
        );
      }
      return field;
    },
    async checkCurrentFieldData(fieldKey, filter) {
      this.selectedFieldsKey=fieldKey;
      if (fieldKey == "apply_rules") {
        filter.data_type = "apply_rules";
        filter.input_type = "apply_rules";

        return;
      }
      if (fieldKey == "created_by") {
        filter.data_type = "created_by";
        filter.input_type = "created_by";
        return;
      }
      if (fieldKey == "updated_by") {
        filter.data_type = "updated_by";
        filter.input_type = "updated_by";
        return;
      }
      if (fieldKey == "created_at") {
        filter.data_type = "created_at";
        filter.input_type = "created_at";
        return;
      }
      if (fieldKey == "updated_at") {
        filter.data_type = "updated_at";
        filter.input_type = "updated_at";
        return;
      }
      if (fieldKey == "NOTIFICATION") {
        return;
      }
      if (fieldKey == "userType") {
        filter.data_type = "userType";
        filter.input_type = "userType";
        return;
      }
      let field = this.allowedFields.concat(this.allowedContentFields).find((field) => field.key == fieldKey);
      if (!field) {
        field = this.getField(fieldKey);
      }
      if (field?.is_individual) {
        filter.is_individual = true;
      }
      if (field?.inputType == "SELECT" || field?.inputType == "MULTI_SELECT") {
        filter.value = [];
      }
      else {
        filter.value = "";
      }
      if (field?.inputType == "FORMULA") {
        if (field?.result_type == "CURRENCY") {
          filter.data_type = "NUMBER";
        } else {
          filter.data_type = field.result_type || "NUMBER";
        }
      }
      else if (field?.inputType == "PAY_BUTTON" && field.isObjField) {
        filter.data_type = this.getTypeFromInputType(field.objFieldType);
      }
      else {
        filter.data_type = this.getTypeFromInputType(field.inputType);
      }
      filter.input_type = field.inputType;
      if (filter.data_type == "BOOLEAN") {
        filter.operator = "=";
      }
      if (field?.inputType == "RELATION" || field?.data_type == "OBJECT_ID") {
        filter.relation_entity_id = field.entity_id;
      }
      await this.getEntilistAsOptions(fieldKey);
    },
    async getEntilistAsOptions(fieldKey) {
      let field = this.getField(fieldKey);
      if (
        field &&
        (field.inputType == "ENTITY" || field.input_type == "RELATION")
      ) {
        await this.fetchEntityPrimaryFieldsData(field.entity_id);
      }
      await this.checkFormTemplateFields(fieldKey);
    },
    async checkFormTemplateFields(fieldKey) {
      let field = this.getField(fieldKey);
      if (
        field?.input_type == "ENTITY_VARIABLE" &&
        field.inputType != "ENTITY" &&
        field.global_variable_entity_field
      ) {
        let tempId = "";
        if (field.global_variable_entity_field.includes("#")) {
          tempId = field.global_variable_entity_field.split("#")[0];
        } else if (field.global_variable_entity_field.includes("~")) {
          tempId = field.global_variable_entity_field.split("~")[0];
        } else if (field.global_variable_entity_field_template_id) {
          tempId = field.global_variable_entity_field_template_id;
        }
        await this.fetchTemplateInfo(tempId);
      }
    },
    async fetchTemplateInfo(template_id) {
      if (template_id && !this.templateDataInfo[template_id]) {
        this.loading = true;
        await this.$store.dispatch(
          "companyTemplates/fetchSingleCompanyTemplate",
          template_id
        );
        if (this.getSingleCompanyTemplate) {
          this.templateDataInfo[template_id] = this.getTemplateFields(
            this.getSingleCompanyTemplate
          );
        }
        this.loading = false;
      }
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        e.template_name = data.name;
        e.data_type = this.getTypeFromInputType(e.inputType);
        return e;
      });
      return fields;
    },
    async fetchEntityPrimaryFieldsData(entity_id) {
      let params = {
        entity_id: entity_id,
        template_fields_data: [],
        filters: [],
      };
      await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      if (this.getEntityRecordsForTable && this.getEntityRecordsForTable.data) {
        this.entityDataInfo.push({
          entity: this.getEntityRecordsForTable.selectedEntity,
          data: this.getEntityRecordsForTable.data,
          entity_id: entity_id,
        });
      }
    },
    checkIsSelect(filter) {
      let field = this.getField(filter.selectField);

      if (
        field &&
        (field.inputType == "SELECT" ||
          field.inputType == "MULTI_SELECT" ||
          field.inputType == "CHECKBOX_GROUP" ||
          field.inputType == "WEEKDAYS" ||
          field.inputType == "RADIO_BUTTON_GROUP" ||
          field.inputType == "PAY_BUTTON" ||
          field.inputType == "LIST")
      ) {
        return true;
      }
      return false;
    },
    checkIsPayButtonExist(filter) {
      console.log(filter);
      let payButtonExist = this.lodash.filter(
        this.currentRule.conditions,
        function (element) {
          return element.input_type === "PAY_BUTTON";
        }
      );
      if (payButtonExist && payButtonExist.length) {
        return true;
      }
      return false;
    },
    allowedDataSources(filter) {
      console.log("filter filter filter", filter);

      let operatorValues = this.operatorValueTypeMap.filter((valueType) => {
        return (
          valueType.operators.indexOf(filter.operator) > -1 &&
          valueType.data_types.indexOf(filter.data_type) > -1
        );
      });

      return operatorValues.map((e) => e.value_type);
    },
    addNewCondition(addAction) {
      this.currentRule.conditions.push({ ...this.formRule });
      if (addAction) {
        this.addNewAction();
        this.addOrEditingRules = true;
      }
    },
 addNewAction(addNew) {
      if (addNew) {
        this.currentRule.actions = [];
      }
      this.currentRule.actions.push({ ...this.actionForm });
    },
 async fetchGlobalVariables() {
      try {
        const query = { get_all: true };

        await this.$store.dispatch(
          "globalVariables/fetchGlobalVariables",
          query
        );

        this.globalVariablesList = [...this.getAllGlobalVariables.data];
        console.log("global variables", this.globalVariablesList);
      } catch (err) {
        console.log(err);
      }
    },
    getCurrentGlobalVaribleOptions(rule) {
      if (rule.data_source == "self_field") {
        let selectedField = this.fieldsData.find(
          (e) => e.key == rule.value_field
        );
        if (selectedField && selectedField.options) {
          return selectedField.options;
        }
      } else {
        let globalVariable = this.globalVariablesList.find(
          (e) => e._id == rule.global_variable_id
        );
        if (globalVariable && globalVariable.options) {
          return globalVariable.options;
        }
      }
      return [];
    },
    checkSelfTemplateFields(rule) {
      let field = this.getField(rule.field);
      if (field && field.input_type) {
        return (this.fieldsData || []).filter(
          (e) => e.input_type == field.input_type
        ).length
          ? true
          : false;
      }
      return false;
    },

    getCurrentFieldOperators(filter) {
      if (filter.selectField === "userType") {
        filter.data_type = "userType";
        filter.input_type = "userType";
        return [
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
        ];
      }
      if (filter.selectField === "created_by") {
        filter.data_type = "created_by";
        filter.input_type = "created_by";
        return [
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
        ];
      }
      if (filter.selectField === "updated_by") {
        filter.data_type = "updated_by";
        filter.input_type = "updated_by";
        return [
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
        ];
      }
      if (filter.selectField === "IsUserType") {
        filter.data_type = "IsUserType";
        filter.input_type = "IsUserType";
        return [
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
        ];
      }
      if (filter.data_type == "created_at"||filter.data_type == "updated_at") {
        return [
          {
            title: "Greater Than (>)",
            value: ">",
          },
          {
            title: "Less Than (<)",
            value: "<",
          },
        ];
      }
      if (filter.selectField) {
        let field = this.getField(filter.selectField);
        if (field && this.getTypeFromInputType(field.inputType)) {
          field.data_type = this.getTypeFromInputType(field.inputType);
        } else if (
          field &&
          field.inputType == "PAY_BUTTON" &&
          field.isObjField
        ) {
          field.data_type = this.getTypeFromInputType(field.objFieldType);
        }
        if (field.inputType == "FORMULA") {
          if (field.result_type == "CURRENCY") {
            field.data_type = "NUMBER";
            return this.operatorsMap[field.data_type];
          }
          return this.operatorsMap[field.result_type];
        }
        return this.operatorsMap[field.data_type];
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      selectedFieldsKey:"",
      searchQuery: '',

      userTypeModel: [],
      data_source: null,
      isTodaySelected: false,
      selectedTemplate: null,
      option: null,
      loading: false,
      formRule: {
        selectField: "",
        operator: "",
        data_source: "",
        value: "",
        startValue: "",
        endValue: "",


      },
      actionForm: {
        selectField: "",
        operator_type: "",
        data_source: "",
        value: "",
        label:"",
      },
      operatorValueTypeMap: [
        {
          value_type: "VALUE",

          operators: [
            "=",
            "<=",
            "<",
            ">",
            ">=",
            "=case-insensitive",
            "=case-sensitive",
            "!=case-insensitive",
            "!=case-sensitive",
            "=text-includes",
            "!ends-with",
            "dateinclude",
            "=starts-with",
            "=ends-with",
            "=not-starts-with",
            "!=",
            "between",
            "ranges",
            "changelabel"
            
            //  "timeRange"


          ],
          data_types: [
            "NUMBER",
            "STRING",
            "DATE",
            "TIME",
            "DATE_TIME",
            "BOOLEAN",
            "CHECKBOX_GROUP",
            "DATE_RANGE",
            "ARRAY",
            "PHONE",
            "created_at",
            "updated_at",
            "changelabel"
            
            
          ],
        },
        {
          value_type: "NEW_LIST",
          operators: ["in", "nin"],
          data_types: ["NUMBER", "STRING"],
        },

        {
          value_type: "ENTITY_DATA_LIST",
          operators: ["in", "nin"],
          data_types: ["OBJECT_ID", "RELATION"],
        },
        {
          value_type: "REAL_TIME_PICKER",
          operators: ["dateinclude"],
          data_types: ["DATE"],
        },
      ],
      loginUserFields: [
        {
          value: "email",
          label: "Email",
        },
        {
          value: "first_name",
          label: "First Name",
        },
        {
          value: "last_name",
          label: "Last Name",
        },
      ],

      operatorsMap: {
        BOOLEAN: [
          {
            title: "YES",
            value: "Yes",
          },
          {
            title: "NO",
            value: "no",
          },
        ],
        QUESTION: [
          {
            title: "YES",
            value: "YES",
          },
          {
            title: "NO",
            value: "NO",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Exist",
            value: "exists",
          },
        ],


        CHECKBOX: [
          {
            title: "CHECKED",
            value: "Checked",
          },
          {
            title: "UNCHECKED",
            value: "Unchecked",
          },
        ],
        RADIO: [
          {
            title: "CHECKED",
            value: "Checked",
          },
          {
            title: "UNCHECKED",
            value: "Unchecked",
          },
        ],
        NUMBER: [
          {
            title: "Equals (=)",
            value: "=",
          },
          {
            title: "Greater Than (>)",
            value: ">",
          },
          {
            title: "Greater Than or Equal(>=)",
            value: ">=",
          },
          {
            title: "Less Than (<)",
            value: "<",
          },
          {
            title: "Less Than or Equal(<=)",
            value: "<=",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Between",
            value: "between",
          },

        ],
        TIME_RANGE:[
        {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],
        IMAGE:[
        {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],
        VIDEO:[
        {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],
        CAPTCHA:[
        {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],
        DATE_RANGE:[
        {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],
        WEEKDAYS: [
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],
        SIGNATURE: [

          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],

        STRING: [
          {
            title: "Equals (Case-sensitive)",
            value: "=case-sensitive",
          },
          {
            title: "Not Equals (Case-sensitive)",
            value: "!=case-sensitive",
          },
          {
            title: "Equals (Case-insensitive)",
            value: "=case-insensitive",
          },
          {
            title: "Not Equals (Case-insensitive)",
            value: "!=case-insensitive",
          },
          {
            title: "Starts With",
            value: "=starts-with",
          },
          {
            title: " Not Starts With",
            value: "=not-starts-with",
          },
          {
            title: "Ends With",
            value: "=ends-with",
          },
          {
            title: "Not Ends With",
            value: "!ends-with",
          },
          {
            title: "Text Includes",
            value: "=text-includes",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Exist",
            value: "exists",
          },
        ],

        OBJECT_ID: [
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Exist",
            value: "exists",
          },
        ],

        TIME: [
          {
            title: "Equals (=)",
            value: "=",
          },
          {
            title: "Greater Than (>)",
            value: ">",
          },
          {
            title: "Greater Than or Equal(>=)",
            value: ">=",
          },
          {
            title: "Less Than (<)",
            value: "<",
          },
          {
            title: "Less Than or Equal(<=)",
            value: "<=",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Exist",
            value: "exists",
          },

        ],
        RELATION: [
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
        ],
        FILE: [
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Exist",
            value: "exists",
          },
        ],
        ARRAY: [
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],
        DATE_TIME: [
        {
            title: "Greater Than (>)",
            value: ">",
          },
          {
            title: "Less Than (<)",
            value: "<",
          },
          {
            title: "Equals (=)",
            value: "=",
          },

          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Exist",
            value: "exists",
          },
        ],
        DATE: [
          {
            title: "Equals (=)",
            value: "=",
          },
          {
            title: "Not Equals (=)",
            value: "!=",
          },
          {
            title: "Greater Than (>)",
            value: ">",
          },
          {
            title: "Greater Than or Equal(>=)",
            value: ">=",
          },
          {
            title: "Less Than (<)",
            value: "<",
          },
          {
            title: "Less Than or Equal(<=)",
            value: "<=",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "range",
            value: "ranges",
          },
        ],
        ACTION: [
          {
            title: "Click",
            value: "click",
          },
        ],
        PHONE: [
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },

        ],
        PAYMENT: [
          {
            title: "Equals (Case-sensitive)",
            value: "=case-sensitive",
          },
          {
            title: "Equals (Case-insensitive)",
            value: "=case-insensitive",
          },
          // {
          //   title: "Text Includes",
          //   value: "=text-includes",
          // },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],
      },
      currentRule: {
        name: "",
        descriptionVisible:false,
        description:"",
        query_type: "AND",
        conditions: [],
        actions: [],
        when: "IMMEDIATELY",
        show_alert: false,
        alert_message: "",
        isRuleActive:true,
      },
      globalVariablesList: [],
      currentRuleIndex: -1,
      addOrEditingRules: false,
      allowedFieldTypes: [
        "SINGLE_LINE_TEXT",
        "IMAGE",
        "VIDEO",
        "FILE",
        "MULTI_LINE_TEXT",
        "DATE",
        "SELECT",
        "NUMBER",
        "MULTI_SELECT",
        "CURRENCY",
        "YES_OR_NO",
        "CHECKBOX",
        "CHECKBOX_GROUP",
        "FORMULA",
        "ENTITY",
        "WEEKDAYS",
        "RADIO",
        "RADIO_BUTTON_GROUP",
          "ACTION_BUTTON",
        "LIST",
        "TIME",
        "GLOBAL_VARIABLE",
        "DATE_TIME",
        "AGGREGATE_FUNCTION",
        "PHONE_COUNTRY_CODE",
        // "DATE_RANGE",
        //  "DATE_TIME_RANGE",
        "STAR_RATING",
        "PAY_BUTTON",
        "AUTO_INCREMENT_NUMBER",
        "DATA_TABLE",
        "ENTITY_VARIABLE",
        "SIGNATURE",
        "ENTITY_TABLE",
        "CAPTCHA",
        "QUESTION"
        

      ],
      allowedContentFieldTypes: [
        "HEADING",
       "SINGLE_LINE_CONTENT",
      //  "ACTION_BUTTON",
       "CONCATENATE",
       "DATE_TIME_RANGE",
       ],
      entityDataInfo: [],
      templateDataInfo: {},

      weekdayOptions: [
        { name: "Monday", value: "MON" },
        { name: "Tuesday", value: "TUE" },
        { name: "Wednesday", value: "WED" },
        { name: "Thursday", value: "THU" },
        { name: "Friday", value: "FRI" },
        { name: "Saturday", value: "SAT" },
        { name: "Sunday", value: "SUN" },
      ],
      starRating_options: [
        { name: "1", value: 1 },
        { name: "1.5", value: 1.5 },
        { name: "2", value: 2 },
        { name: "2.5", value: 2.5 },
        { name: "3", value: 3 },
        { name: "3.5", value: 3.5 },
        { name: "4", value: 4 },
        { name: "4.5", value: 4.5 },
        { name: "5", value: 5 },
      ],
      allowedLoginRuleOp: [
        "=text-includes",
        "=case-insensitive",
        "=case-sensitive",
      ],
      formbuildersList: []
    };
  },

  created() {
    bus.$on("form-rule-dialog-closed", (data) => {
      console.log("Form rule closed", data);
      if (data) {
        if (this.templateRules.length) {
          //do action when template rules exist
        } else {
          this.addOrEditingRules = false;
          this.currentRule = {
            name: "",
            description:"",
            query_type: "AND",
            conditions: [],
            actions: [],
          };
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.form-rules-wrapper{
  max-height: 50vh !important;
  scrollbar-width: thin;
  overflow-y: auto;
  width:100%;
}
.action-links {
  float: right !important;
}
.small-input {
  width: 180px;
}


.rule-collapse {
  border: 1px solid #e4e7ed;
  border-radius: 3px;
}

.empty-alert {
  text-align: center !important;

  img {
    width: 100px;
    margin-bottom: 10px;
  }

  .add-rule {}
}

.rule-row {
  display: flex !important;
  background: #f2f6fc;
  height: 55px;

  .rule-inner-row {
    display: grid;
    margin: auto;
    margin-right: 25px;
    margin-left: 25px;
    grid-template-columns: auto auto auto auto;
  }
}

.action-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.rule-preview {
  padding: 10px;
  font-size: 12px;

  img {
    width: 25px;
  }
}

.el-date {}
</style>
